import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/jxie/projects/lyra/packages/web/src/components/content/ContentPage.tsx";
import goals from '../../components/content/goals/m6';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const GoalSetting = makeShortcode("GoalSetting");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h2 {...{
      "id": "putting-it-into-practice"
    }}>{`Putting it into practice`}</h2>
    <p>{`Below is a list of activities covered in this module. Your task is to select an activity and commit to
practising it over the next week.`}</p>
    <p>{`At the end of the week you'll receive an email to see how you did or to remind you to practise the strategy.`}</p>
    <GoalSetting goals={goals} mdxType="GoalSetting" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      